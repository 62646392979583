<script>
import MateriSettingJadwal from "@/router/views/dashboards/setting-jadwal/materi-setting-jadwal";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { ref } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  props: [
    "idJadwal",
    "simulasiId",
    "simulasiNama",
    "jenisAsesmen",
    "idMateri",
    "jenisMateri",
    "tipeMateri",
    "kelompokNama",
    "tipePenjadwalan",
    "jumlahSoal",
    "tanggalMulai",
    "tanggalSelesai",
    "tanggalMulaiJadwal",
    "tanggalSelesaiJadwal",
    "detailAsesor",
    "jadwalMateri"
  ],
  components: {
    MateriSettingJadwal,
    VueDatePicker,
  },
  data() {
    return {
      jadwalID:this.$props.idJadwal,
      materiJadwal: this.$props.jadwalMateri,
      isShowModal: false,
      loadingTableAsesor: true,

      listBatch: [],
      postData: [],
      durasi_pengerjaan: null,
      batch_id_global: "",
      
      tanggal_awal_global: new Date(),
      tanggal_akhir_global: new Date(),
      tanggal_awal_jadwal: new Date(this.$props.tanggalMulaiJadwal),
      tanggal_akhir_jadwal: new Date(this.$props.tanggalSelesaiJadwal),
      validasi_tanggal_awal: true,
      validasi_tanggal_akhir: true
    };
  },
  mounted() {
    this.setPostData();
    this.getListBatch();
    console.log(this.tanggal_awal_jadwal);
    console.log(this.tanggal_akhir_jadwal);
  },
  methods: {
    setPostData() {
      let self = this;

      let config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION + "dashboard/data-batch-jadwal-simulasi",
        params: {
          dataAsesor: JSON.stringify(self.$props.detailAsesor),
          jadwal_id: self.$props.idJadwal,
          simulasi_id: self.$props.simulasiId,
          tipe_penjadwalan: self.$props.tipePenjadwalan,
          jumlah_soal: self.$props.jumlahSoal
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
      .then((response) => {
        let response_data = response.data;
        console.log(response_data.data);
        self.durasi_pengerjaan = response_data.data.durasi_pengerjaan;
        self.batch_id_global = response_data.data.batch_id_global;
        self.tanggal_awal_global = ref(new Date(response_data.data.tanggal_awal_global));
        self.tanggal_akhir_global = ref(new Date(response_data.data.tanggal_akhir_global));
        self.postData = response_data.data.data_asesor
      })
      .catch((errors) => {
        console.log(errors);
      });
      console.log(self.postData);
      self.loadingTableAsesor = false;
    },
    getListBatch() {
      let self = this;
      let config = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "referensi/batch-simulasi-jadwal",
        params: {
          simulasi_id: self.$props.simulasiId,
          jadwal_id: self.$props.idJadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          self.listBatch = response_data.data.referensi;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    checkPostData() {
      let self = this;
      console.log(self.postData);
    },
    validateTanggalMulai(){
      let self = this;
      let tanggal_awalnya = moment(self.tanggal_awal_jadwal).format("YYYY-MM-DD");
      let tanggal_akhirnya = moment(self.tanggal_akhir_jadwal).format("YYYY-MM-DD");
      let tanggal_mulai_validasi = moment(self.tanggal_awal_jadwal).format("YYYY-MM-DD");
      let tanggal_akhir_validasi = moment(self.tanggal_akhir_jadwal).format("YYYY-MM-DD");  
      let tanggal_mulai_form = moment(self.tanggal_awal_global).format("YYYY-MM-DD");
      if(tanggal_mulai_form < tanggal_mulai_validasi){
        self.validasi_tanggal_awal = false;
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          text: "Tanggal Mulai dan Tanggal Selesai harus diantara Tanggal " + tanggal_awalnya + " s.d. " + tanggal_akhirnya,
        });
      }else{
        if(tanggal_mulai_form > tanggal_akhir_validasi){
          self.validasi_tanggal_awal = false;
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            text: "Tanggal Mulai dan Tanggal Selesai harus diantara Tanggal " + tanggal_awalnya + " s.d. " + tanggal_akhirnya,
          });
        }else{
          self.validasi_tanggal_awal = true;
        }
      }
    },
    validateTanggalAkhir(){
      let self = this;
      let tanggal_awalnya = moment(self.tanggal_awal_jadwal).format("YYYY-MM-DD");
      let tanggal_akhirnya = moment(self.tanggal_akhir_jadwal).format("YYYY-MM-DD");
      let tanggal_mulai_validasi = moment(self.tanggal_awal_jadwal).format("YYYY-MM-DD");
      let tanggal_akhir_validasi = moment(self.tanggal_akhir_jadwal).format("YYYY-MM-DD");
      let tanggal_akhir_form = moment(self.tanggal_akhir_global).format("YYYY-MM-DD");
      if(tanggal_akhir_form < tanggal_mulai_validasi){
        self.validasi_tanggal_akhir = false;
        Swal.fire({
          icon: "error",
          title: "Tanggal Tidak Valid",
          text: "Tanggal Mulai dan Tanggal Selesai harus diantara Tanggal " + tanggal_awalnya + " s.d. " + tanggal_akhirnya,
        });
      }else{
        if(tanggal_akhir_form > tanggal_akhir_validasi){
          self.validasi_tanggal_akhir = false;
          Swal.fire({
            icon: "error",
            title: "Tanggal Tidak Valid",
            text: "Tanggal Mulai dan Tanggal Selesai harus diantara Tanggal " + tanggal_awalnya + " s.d. " + tanggal_akhirnya,
          });
        }else{
          self.validasi_tanggal_akhir = true
        }
      }
    },
    StoreDataJadwalBatch(){
      let self = this;
      let tanggal_awalnya = moment(self.tanggal_awal_jadwal).format("YYYY-MM-DD");
      let tanggal_akhirnya = moment(self.tanggal_akhir_jadwal).format("YYYY-MM-DD");

      Swal.fire({
        title: '<i class="bx bx-loader-alt bx-spin bx-lg"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var configStore = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "dashboard/store-batch-jadwal-simulasi",
        data: {
          dataAsesor: JSON.stringify(self.postData),
          jadwal_id: self.$props.idJadwal,
          simulasi_id: self.$props.simulasiId,
          tipe_penjadwalan: self.$props.tipePenjadwalan,
          jumlah_soal: self.$props.jumlahSoal,
          durasi: self.durasi_pengerjaan,
          batch_id_global: self.batch_id_global,
          tanggal_awal_global: moment(self.tanggal_awal_global).format("YYYY-MM-DD HH:mm:ss"),
          tanggal_akhir_global: moment(self.tanggal_akhir_global).format("YYYY-MM-DD HH:mm:ss")
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      if(this.$props.tipePenjadwalan == '1 Simulasi 1 Batch' && (self.validasi_tanggal_awal == false || self.validasi_tanggal_akhir == false)){
        Swal.fire({
          icon: "error",
          title: "Tanggal Tidak Valid",
          text: "Tanggal Mulai dan Tanggal Selesai harus diantara Tanggal " + tanggal_awalnya + " s.d. " + tanggal_akhirnya,
        });
      }else{
        axios(configStore).then((response) => {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Data berhasil disimpan",
            timer: 1500,
            showConfirmButton: false,
          });
          location.reload();
        }).catch((errors) => {
          console.log(errors.response.data.data.error);
          let error = errors.response.data.data.error;
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            text: error,
          });
        });
      }
    },
    submitUpdateData(index){
      let self = this;
      let data_single = self.postData[index];
      
      if(data_single.batch_id == ""){
        Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Mohon Pilih Batch",
            });
      }else if(data_single.link_zoom == ""){
        Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Mohon Isi Link Zoom",
            });
      }else{
        Swal.fire({
          title: '<i class="bx bx-loader-alt bx-spin bx-lg"></i>',
          text: "Loading...",
          showConfirmButton: false,
        });

        var configUpdate = {
          method: "put",
          url:
            process.env.VUE_APP_BACKEND_URL_VERSION +
            "dashboard/update-single-batch-jadwal",
          data: {
            id_jadwal_batch: data_single.id_jadwal_batch,
            tipe_penjadwalan: self.$props.tipePenjadwalan,
            batch_id: data_single.batch_id,
            link_zoom: data_single.link_zoom,
          },
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
            access: "all-role",
            role_id: localStorage.session_role_active_id,
          },
        };
        axios(configUpdate).then((response) => {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Data berhasil diperbarui",
            timer: 1500,
            showConfirmButton: false,
          });
        }).catch((errors) => {
          let error = errors.response.data.data.error;
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            text: error,
          });
        });
      }
    },
    getSimulasiLampau()
    {
      let self = this;
      Swal.fire({
        title: '<i class="bx bx-loader-alt bx-spin bx-lg"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "dashboard/get-simulasi-lampau-batch",
        data: {
          idJadwal: self.$props.idJadwal,
          idSimulasi: self.$props.simulasiId,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config).then((response) => {
        console.log(response);
        Swal.fire({
          icon: "success",
          title: "Data berhasil diproses.",
          timer: 1500,
          showConfirmButton: false,
        });
        window.location.reload();
      }).catch((errors) => {
        let error = errors.response.data.data.error;
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          text: error,
        });
      });
    }
  },
};
</script>

<template>
  <!-- Tipe Penjadwalan 1 Simulasi 1 Batch, Jumlah soal Multiple -->
  <div
    v-if="tipePenjadwalan == '1 Simulasi 1 Batch' && jumlahSoal == 'Multiple'"
  >
  <b-form @submit.prevent="StoreDataJadwalBatch">
    <div class="row">
      <div class="col-12">
        <table class="table table-bordered table-condensed">
          <thead>
            <tr class="text-center bg-secondary text-white">
              <th colspan="4">{{ simulasiNama }} - {{ tipeMateri }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="4" class="text-center">
                <b>Waktu Ujian</b>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <b-form-group
                  class="mb-3"
                  label="Tanggal Waktu Mulai"
                  label-for="formrow-tanggal_mulai"
                >
                  <VueDatePicker
                    v-model="tanggal_awal_global"
                    :teleport="true"
                    :format="'dd/MM/yyyy HH:mm'"
                    position="left"
                    auto-apply
                    @update:model-value="validateTanggalMulai"
                  ></VueDatePicker>
                </b-form-group>
              </td>
              <td colspan="2">
                <b-form-group
                  class="mb-3"
                  label="Tanggal Waktu Selesai"
                  label-for="formrow-tanggal_akhir"
                >
                  <VueDatePicker
                    v-model="tanggal_akhir_global"
                    :teleport="true"
                    :format="'dd/MM/yyyy HH:mm'"
                    position="left"
                    auto-apply
                    @update:model-value="validateTanggalAkhir"
                  ></VueDatePicker>
                </b-form-group>
              </td>
            </tr>
            <tr>
              <td>Durasi Pengerjaan (Menit)</td>
              <td colspan="2">
                <input type="hidden" v-model="batch_id_global">
                <input
                  type="number"
                  class="form-control form-control-sm"
                  placeholder="Masukkan Durasi Pengerjaan (Menit)"
                  v-model="durasi_pengerjaan"
                  required="true"
                />
              </td>
              <td>
                <center>
                  <router-link
                    :to="{
                      name: 'all-list-jadwal',
                      params: {
                        idJadwal: jadwalID,
                      },
                      query: {
                        level: materiJadwal,
                      },
                    }"
                    class="btn btn-success btn-sm"
                  >
                    <i class="fa fa-list"></i> List Jadwal
                  </router-link>
                  &nbsp;
                  <button type="button" class="btn btn-info btn-sm d-none" @click="getSimulasiLampau()"><i class="fa fa-download"></i> Jadwal Lain</button>
                </center>
              </td>
            </tr>
            <tr>
              <td class="text-center" colspan="2">Asesor</td>
              <td class="text-center" colspan="2">Asesi</td>
            </tr>
            <tr v-if="loadingTableAsesor">
              <td colspan="4">
                <i class="fas fa-spinner fa-spin"></i> Loading...
              </td>
            </tr>
            <tr v-else v-for="(asesor, key) in postData" :key="key">
              <td>{{ asesor.nama_asesor }}</td>
              <td>{{ asesor.nip_asesor }}</td>
              <td>{{ asesor.nama_asesi }}</td>
              <td>{{ asesor.nip_asesi }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="tipeMateri == 'Ambil File dan Pilih'">
      <div class="row">
        <div class="col-6 mb-2">
          <div class="d-grid gap-2">
            <button
              class="btn btn-info"
              type="button"
              @click="isShowModal = true"
            >
              <i class="bx bx-shuffle"></i> Ambil File dan Pilih Materi
            </button>
          </div>
        </div>
        <div class="col-6 mb-2">
          <div class="d-grid gap-2">
            <button class="btn btn-primary" type="submit">
              <i class="bx bx-save"></i> Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="tipeMateri == 'Ambil File & Acak'">
      <div class="row">
        <div class="col-6 mb-2">
          <div class="d-grid gap-2">
            <button
              class="btn btn-info"
              type="button"
              @click="isShowModal = true"
            >
              <i class="bx bx-shuffle"></i> Ambil File dan Acak
            </button>
          </div>
        </div>
        <div class="col-6 mb-2">
          <div class="d-grid gap-2">
            <button class="btn btn-primary" type="submit">
              <i class="bx bx-save"></i> Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-12 mb-2">
          <div class="d-grid gap-2">
            <button class="btn btn-primary" type="submit">
              <i class="bx bx-save"></i> Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-form>
  </div>

  <!-- Tipe Penjadwalan 1 Simulasi 1 Batch, Jumlah soal Single -->
  <div v-if="tipePenjadwalan == '1 Simulasi 1 Batch' && jumlahSoal == 'Single'">
    <b-form @submit.prevent="StoreDataJadwalBatch">
    <div class="row">
      <div class="col-12">
        <table class="table table-bordered table-condensed">
          <thead>
            <tr class="text-center bg-secondary text-white">
              <th colspan="4">
                {{ simulasiNama }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="4" class="text-center">
                <b>Waktu Ujian</b>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <b-form-group
                  class="mb-3"
                  label="Tanggal Waktu Mulai"
                  label-for="formrow-tanggal_mulai"
                >
                  <VueDatePicker
                    v-model="tanggal_awal_global"
                    :teleport="true"
                    :format="'dd/MM/yyyy HH:mm'"
                    position="left"
                    auto-apply
                    @update:model-value="validateTanggalMulai"
                  ></VueDatePicker>
                </b-form-group>
              </td>
              <td colspan="2">
                <b-form-group
                  class="mb-3"
                  label="Tanggal Waktu Selesai"
                  label-for="formrow-tanggal_akhir"
                >
                  <VueDatePicker
                    v-model="tanggal_akhir_global"
                    :teleport="true"
                    :format="'dd/MM/yyyy HH:mm'"
                    position="left"
                    auto-apply
                    @update:model-value="validateTanggalAkhir"
                  ></VueDatePicker>
                </b-form-group>
              </td>
            </tr>
            <tr>
              <td>Durasi Pengerjaan (Menit)</td>
              <td colspan="2">
                <input type="hidden" v-model="batch_id_global">
                <input
                  type="number"
                  class="form-control form-control-sm"
                  placeholder="Masukkan Durasi Pengerjaan (Menit)"
                  v-model="durasi_pengerjaan"
                  required="true"
                />
              </td>
              <td>
                <center>
                  <router-link
                    :to="{
                      name: 'all-list-jadwal',
                      params: {
                        idJadwal: jadwalID,
                      },
                      query: {
                        level: materiJadwal,
                      },
                    }"
                    class="btn btn-success btn-sm"
                  >
                    <i class="fa fa-list"></i> List Jadwal
                  </router-link>
                  &nbsp;
                  <button type="button" class="btn btn-info btn-sm d-none" @click="getSimulasiLampau()"><i class="fa fa-download"></i> Jadwal Lain</button>
                </center>
              </td>
            </tr>
            <tr>
              <td class="text-center" colspan="2">Asesor</td>
              <td class="text-center" colspan="2">Asesi</td>
            </tr>
            <tr v-if="loadingTableAsesor">
              <td colspan="4">
                <i class="fas fa-spinner fa-spin"></i> Loading...
              </td>
            </tr>
            <tr v-else v-for="(asesor, key) in postData" :key="key">
              <td>{{ asesor.nama_asesor }}</td>
              <td>{{ asesor.nip_asesor }}</td>
              <td>{{ asesor.nama_asesi }}</td>
              <td>{{ asesor.nip_asesi }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 mb-2">
        <div class="d-grid gap-2">
          <button class="btn btn-primary" type="submit">
            <i class="bx bx-save"></i> Simpan
          </button>
        </div>
      </div>
    </div>
  </b-form>
  </div>

  <!-- Tipe Penjadwalan 1 Simulasi per orang beda batch, Jumlah soal Multiple -->
  <div
    v-if="
      tipePenjadwalan == '1 Simulasi per orang beda batch' &&
      jumlahSoal == 'Multiple'
    "
  >
  <b-form @submit.prevent="StoreDataJadwalBatch">
    <div class="row">
      <div class="col-12">
        <table class="table table-bordered table-condensed">
          <thead>
            <tr class="text-center bg-secondary text-white">
              <th colspan="2">
                {{ simulasiNama }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">Tanggal</td>
              <td class="text-center">
                {{
                  tanggalMulai != null ? fullDateTimeFormat(tanggalMulai) : "-"
                }}
              </td>
            </tr>
            <tr>
              <td class="text-center">Materi</td>
              <td>{{ jenisAsesmen + " - " + jenisMateri }}</td>
            </tr>
            <tr v-if="loadingTableAsesor">
              <td colspan="2">
                <i class="fas fa-spinner fa-spin"></i> Loading...
              </td>
            </tr>
            <tr v-else>
              <td colspan="2">
                <table class="table table-bordered table-condensed">
                  <thead>
                    <tr>
                      <td class="text-center">Asesor</td>
                      <td class="text-center">PIN Asesi</td>
                      <td class="text-center">Asesi</td>
                      <td class="text-center">Roleplay</td>
                      <td class="text-center">Batch</td>
                      <td class="text-center">Link Zoom</td>
                      <td class="text-center">#</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(asesor, key) in postData" :key="key">
                      <td>{{ asesor.nama_asesor }}</td>
                      <td>{{ asesor.pin }}</td>
                      <td>{{ asesor.nama_asesi }}</td>
                      <td>
                        <div v-if="asesor.nip_role_player == '-'">
                          {{ '-' }}
                        </div>
                        <div v-else>
                          {{ asesor.nama_role_player + ' (' + asesor.nip_role_player + ')' }}
                        </div>
                      </td>
                      <td>
                        <select
                          class="form-control"
                          v-model="asesor.batch_id"
                          @change="checkPostData()"
                          required="true"
                        >
                          <option value="">-- Pilih Batch --</option>
                          <option
                            v-for="(val_batch, key_batch) in listBatch"
                            :key="key_batch"
                            :value="val_batch.id_batch"
                          >
                          {{ val_batch.nama_batch + " (" + fullDateTimeFormat(val_batch.tanggal_awal) + " s.d. " + fullDateTimeFormat(val_batch.tanggal_akhir) + ")" }}
                          </option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Masukkan Link Zoom"
                          v-model="asesor.link_zoom"
                          @change="checkPostData()"
                          required="true"
                        />
                      </td>
                      <td class="text-center">
                        <div v-if="asesor.id_jadwal_batch == null">
                          -
                        </div>
                        <div v-else>
                          <button type="button" class="btn btn-info" @click="submitUpdateData(key)">
                          <i class="bx bx-save"></i>
                        </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="tipeMateri == 'Ambil File dan Pilih'">
      <div class="row">
        <div class="col-6 mb-2">
          <div class="d-grid gap-2">
            <button
              class="btn btn-info"
              type="button"
              @click="isShowModal = true"
            >
              <i class="bx bx-shuffle"></i> Ambil File dan Pilih Materi
            </button>
          </div>
        </div>
        <div class="col-6 mb-2">
          <div class="d-grid gap-2">
            <button class="btn btn-primary" type="submit">
              <i class="bx bx-save"></i> Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="tipeMateri == 'Ambil File & Acak'">
      <div class="row">
        <div class="col-6 mb-2">
          <div class="d-grid gap-2">
            <button
              class="btn btn-info"
              type="button"
              @click="isShowModal = true"
            >
              <i class="bx bx-shuffle"></i> Ambil File dan Acak
            </button>
          </div>
        </div>
        <div class="col-6 mb-2">
          <div class="d-grid gap-2">
            <button class="btn btn-primary" type="submit">
              <i class="bx bx-save"></i> Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-12 mb-2">
          <div class="d-grid gap-2">
            <button class="btn btn-primary" type="submit">
              <i class="bx bx-save"></i> Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-form>
  </div>

  <!-- Tipe Penjadwalan 1 Simulasi per orang beda batch, Jumlah soal Single -->
  <div
    v-if="
      tipePenjadwalan == '1 Simulasi per orang beda batch' &&
      jumlahSoal == 'Single'
    "
  >
  <b-form @submit.prevent="StoreDataJadwalBatch">
    <div class="row">
      <div class="col-12">
        <table class="table table-bordered table-condensed">
          <thead>
            <tr class="text-center bg-secondary text-white">
              <th colspan="2">
                {{ simulasiNama }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">Tanggal</td>
              <td class="text-center">
                {{
                  tanggalMulai != null ? fullDateTimeFormat(tanggalMulai) : "-"
                }}
              </td>
            </tr>
            <tr>
              <td class="text-center">Materi</td>
              <td>{{ jenisAsesmen + " - " + jenisMateri }}</td>
            </tr>
            <tr v-if="loadingTableAsesor">
              <td colspan="2">
                <i class="fas fa-spinner fa-spin"></i> Loading...
              </td>
            </tr>
            <tr v-else>
              <td colspan="2">
                <table class="table table-bordered table-condensed">
                  <thead>
                    <tr>
                      <td class="text-center">Asesor</td>
                      <td class="text-center">PIN Asesi</td>
                      <td class="text-center">Asesi</td>
                      <td class="text-center">Roleplay</td>
                      <td class="text-center">Batch</td>
                      <td class="text-center">Link Zoom</td>
                      <td class="text-center">#</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(asesor, key) in postData" :key="key">
                      <td>{{ asesor.nama_asesor }}</td>
                      <td>{{ asesor.pin }}</td>
                      <td>{{ asesor.nama_asesi }}</td>
                      <td>
                        <div v-if="asesor.nip_role_player == '-'">
                          {{ '-' }}
                        </div>
                        <div v-else>
                          {{ asesor.nama_role_player + ' (' + asesor.nip_role_player + ')' }}
                        </div>
                      </td>
                      <td>
                        <select
                          class="form-control"
                          v-model="asesor.batch_id"
                          @change="checkPostData()"
                          required="true"
                        >
                          <option value="">-- Pilih Batch --</option>
                          <option
                            v-for="(val_batch, key_batch) in listBatch"
                            :key="key_batch"
                            :value="val_batch.id_batch"
                          >
                            {{ val_batch.nama_batch + " (" + fullDateTimeFormat(val_batch.tanggal_awal) + " s.d. " + fullDateTimeFormat(val_batch.tanggal_akhir) + ")" }}
                          </option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Masukkan Link Zoom"
                          v-model="asesor.link_zoom"
                          @change="checkPostData()"
                          required="true"
                        />
                      </td>
                      <td class="text-center">
                        <div v-if="asesor.id_jadwal_batch == null">
                          -
                        </div>
                        <div v-else>
                          <button type="button" class="btn btn-info" @click="submitUpdateData(key)">
                            <i class="bx bx-save"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 mb-2">
        <div class="d-grid gap-2">
          <button class="btn btn-primary" type="submit">
            <i class="bx bx-save"></i> Simpan
          </button>
        </div>
      </div>
    </div>
    </b-form>
  </div>

  <b-modal
    v-model="isShowModal"
    id="modal-lg"
    size="xl"
    :title="tipeMateri"
    title-class="font-18"
    hide-footer
  >
    <MateriSettingJadwal
      @closeModal="isShowModal = false"
      v-if="isShowModal"
      :tipeMateri="tipeMateri"
      :idJadwal="idJadwal"
      :simulasiId="simulasiId"
      :idMateri="idMateri"
      :dataSettingJadwal="postData"
    />
  </b-modal>
</template>
